'use client';

import React from 'react';
import Footer, { BottomLinkFooter, Props as FooterProps, Time } from 'components/commercetools-ui/organisms/footer';
import { FooterLink } from 'components/commercetools-ui/organisms/footer/atoms/column';
import { TasticProps } from '../types';

interface Props extends FooterProps {
  headerCol1: string;
  linksCol1: FooterLink[];
  headerCol2: string;
  linksCol2: FooterLink[];
  headerCol3: string;
  linksCol3: FooterLink[];
  headerCol4: string;
  linksCol4: FooterLink[];
  headerPaymentMethods?: string; //Added V2
  paymentMethods?: BottomLinkFooter[]; //Added V2
  headerDeliveryOptions?: string; //Added V2
  deliveryOptions?: BottomLinkFooter[]; //Added V2
  headerPartnerWebsites?: string; //Added V2
  partnerWebsites?: BottomLinkFooter[]; //Added V2
  phoneNumber?: string; //Added V2
  headerSocialMedia?: string; //Added V2
  socialMedia?: BottomLinkFooter[]; //Added V2
  weekdays?: Time[]; //Added V2
  timeRange?: Time[]; //Added V2
}

const FooterTastic = ({ data }: TasticProps<Props>) => {
  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
    },
    {
      header: data.headerCol4,
      phoneNumber: data.phoneNumber,
      availableDays: data.weekdays?.[0],
      availableHours: data.timeRange?.[0],
      links: data.linksCol4,
    },
  ];

  const paymentMethodsObject = {
    header: data.headerPaymentMethods,
    links: data.paymentMethods,
  };

  const deliveryOptionsObject = {
    header: data.headerDeliveryOptions,
    links: data.deliveryOptions,
  };

  const partnerWebsitesObject = {
    header: data.headerPartnerWebsites,
    links: data.partnerWebsites,
  };

  const socialMediaObject = {
    header: data.headerSocialMedia,
    links: data.socialMedia,
  };

  return (
    <div className="fixed-screen-width lg:relative-width">
      <Footer
        {...data}
        columns={columns}
        paymentMethodsObject={paymentMethodsObject}
        deliveryOptionsObject={deliveryOptionsObject}
        partnerWebsitesObject={partnerWebsitesObject}
        socialMediaObject={socialMediaObject}
      />
    </div>
  );
};

export default FooterTastic;
