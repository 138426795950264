export const PRODUCT_DETAIL_PAGE = 'frontastic/product-detail-page';
export const SEARCH_PAGE = 'frontastic/search';
export const CATEGORY_PAGE = 'frontastic/category';
export const CART_PAGE = 'frontastic/cart';
export const CHECKOUT_PAGE = 'frontastic/checkout';
export const ACCOUNT_PAGE = 'frontastic/account';
export const LOGIN_PAGE = 'frontastic/login';
export const REGISTER_PAGE = 'frontastic/register';
export const ACTIVATION_PAGE = 'frontastic/activation';
export const RESET_PASSWORD_PAGE = 'frontastic/reset-password';
export const THANK_YOU_PAGE = 'frontastic/thank-you';
