'use client';

import React from 'react';
import ThankYou from 'components/commercetools-ui/organisms/thank-you';

const ThankYouTastic = () => {
  return <ThankYou />;
};

export default ThankYouTastic;
