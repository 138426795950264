import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import Tile, { TileProps } from '../tile';

export interface Props {
  tiles: TileProps[];
  variant: 'standard' | 'slim';
  as: TilesGroupAs;
}

export type TilesGroupAs = { ['tile']: { title: TypographyProps['as'] } } | undefined;

const TilesGroup: React.FC<Props> = ({ tiles, variant = 'standard', as }) => {
  const numOfTiles = tiles.length;
  const imgTargetSizeOnTablet = Math.ceil(100 / numOfTiles);
  const imgTargetSizeOnDesktop = Math.ceil(1440 / numOfTiles);

  const imageSizes = `
  (max-width: 743px) 100vw,
  (max-width: 1023px) ${imgTargetSizeOnTablet}vw,
  ${imgTargetSizeOnDesktop}px,
  `;

  return (
    <div className="bg-background lg:px-20 xl:px-48">
      <Container>
        <div className="flex flex-col bg-background md:flex-row lg:gap-16">
          {tiles.map((tile, index) => (
            <Tile
              key={index}
              {...tile}
              as={as?.tile.title}
              image={{ ...tile.image, fill: true, sizes: imageSizes, alt: tile.title ?? '' }}
              className={`[&_img]:!static [&_img]:!w-full ${
                variant === 'standard'
                  ? '[&_img]:!max-h-[320px] lg:[&_img]:!max-h-[475px]'
                  : '[&_img]:max-h-[180px] lg:[&_img]:max-h-[200px]'
              } `}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default TilesGroup;
