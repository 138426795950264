'use client';

import React from 'react';
import { FooterLink } from 'components/commercetools-ui/organisms/footer/atoms/column';
import FooterAccount from 'components/commercetools-ui/organisms/footer-account';
import { TasticProps } from '../types';

interface Props {
  copyright: string;
  privacy: FooterLink[];
}

const FooterAccountTastic = ({ data }: TasticProps<Props>) => {
  return (
    <div className="fixed-screen-width lg:relative-width">
      <FooterAccount {...data} />
    </div>
  );
};

export default FooterAccountTastic;
