import React from 'react';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import Container from 'components/commercetools-ui/atoms/container';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screensizes from 'helpers/utils/screensizes';
import { Reference } from 'types/reference';
import { ImageProps } from 'frontastic/lib/image';
import Column, { FooterLink } from './atoms/column';
import FooterBottom from './footer-bottom';

export interface FooterColumn {
  header?: string;
  links?: BottomLinkFooter[];
}

export interface FooterColumnLinks {
  header?: string;
  links?: FooterLink[];
  phoneNumber?: string;
  availableDays?: Time;
  availableHours?: Time;
}
export interface Props {
  columns: FooterColumnLinks[];
  logo?: ImageProps; //DEPRECATED V2
  socialMediaObject?: FooterColumn;
  deliveryOptionsObject?: FooterColumn;
  paymentMethodsObject?: FooterColumn;
  partnerWebsitesObject?: FooterColumn;
}

export interface BottomLinkFooter {
  logo: ImageProps;
  reference: Reference;
}

export interface Time {
  startTime?: string;
  endTime?: string;
}

const Footer: React.FC<Props> = ({
  columns,
  logo,
  socialMediaObject,
  deliveryOptionsObject,
  paymentMethodsObject,
  partnerWebsitesObject,
}) => {
  const [isBiggerThanTabletSize] = useMediaQuery(screensizes.tablet);

  return (
    <footer aria-labelledby="footer-heading" className="w-full bg-base-accent-3">
      {isBiggerThanTabletSize ? (
        <Container>
          <div className="flex w-full flex-col justify-between gap-32 px-46 py-32">
            <div className="grid w-full grid-cols-4">
              {columns?.map((column, index) => (
                <div key={index} className="flex items-start gap-12">
                  <Column
                    header={column.header}
                    links={column.links ?? []}
                    phoneNumber={column.phoneNumber ?? ''}
                    availableDays={column.availableDays ?? {}}
                    availableHours={column.availableHours ?? {}}
                    className={`flex flex-col items-center lg:items-start ${
                      index + 1 === columns.length ? 'gap-20' : 'gap-24'
                    }`}
                  />
                </div>
              ))}
            </div>
            <div className="border-t border-base-accent-2" />
          </div>
          <FooterBottom
            socialMedia={socialMediaObject}
            logo={logo}
            paymentMethods={paymentMethodsObject}
            partnerWebsites={partnerWebsitesObject}
            deliveryOptions={deliveryOptionsObject}
          />
        </Container>
      ) : (
        <>
          <ul className="flex flex-col pb-32 pt-16">
            {columns?.map((column, index) => (
              <li key={index} className="w-full px-20">
                <Accordion
                  variant="arrow"
                  closedSectionTitle={column.header ?? ''}
                  openSectionTitle={column.header}
                  buttonClassName="h-48 text-16 leading-5 font-bold border-b border-neutral-2 px-12"
                  iconClassName="text-neutral-4"
                >
                  <div className="my-16 ml-12 flex flex-row text-14" key={index}>
                    <Column
                      links={column.links ?? []}
                      className="px-0"
                      phoneNumber={column.phoneNumber ?? ''}
                      availableDays={column.availableDays ?? {}}
                      availableHours={column.availableHours ?? {}}
                    />
                  </div>
                </Accordion>
              </li>
            ))}
            <li className="w-full px-20">
              <Accordion
                variant="arrow"
                closedSectionTitle={paymentMethodsObject?.header ?? ''}
                openSectionTitle={paymentMethodsObject?.header}
                buttonClassName="h-48 text-16 leading-5 font-bold border-b border-neutral-2 px-12"
                iconClassName="text-neutral-4"
              >
                <div className="my-16 ml-12 flex flex-row text-14" key={paymentMethodsObject?.header}>
                  <Column paymentMethodsLinks={paymentMethodsObject?.links ?? []} className="px-0" />
                </div>
              </Accordion>
            </li>
          </ul>
          <FooterBottom
            socialMedia={socialMediaObject}
            logo={logo}
            paymentMethods={paymentMethodsObject}
            partnerWebsites={partnerWebsitesObject}
            deliveryOptions={deliveryOptionsObject}
          />
        </>
      )}
    </footer>
  );
};

export default Footer;
