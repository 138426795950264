import { useMemo } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItem from './cart-item';
import useTrack from '../useTrack';

const CartItemsList = () => {
  const { data } = useCart();

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const lineItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const soldOutItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => !lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const discountCodes = data?.discountCodes;

  const { ref } = useTrack({ lineItems });

  return (
    <div ref={ref} className="flex flex-col gap-24 lg:gap-32">
      <div className="mt-16 flex flex-col gap-12 lg:mt-20">
        {lineItems.map((lineItem) => (
          <div key={lineItem.lineItemId}>
            <CartItem item={lineItem} discountCodes={discountCodes} />
          </div>
        ))}
      </div>
      {soldOutItems.length > 0 && (
        <div>
          <h3 className="text-20 font-bold leading-[25px]">
            {formatProductMessage({ id: 'sold.out', defaultMessage: 'Sold out' })}
          </h3>
          <div className="mt-12 flex flex-col gap-12">
            {soldOutItems.map((lineItem) => (
              <div key={lineItem.lineItemId}>
                <CartItem item={lineItem} discountCodes={discountCodes} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItemsList;
