import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'next/navigation';
import Container from 'components/commercetools-ui/atoms/container';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import CartContent from './components/cart-content';
import { CartProps } from './types';
import OrderOverview from '../order-overview';
import CheckoutButton from '../order-overview/components/checkout-button';
import useCostsData from '../order-payment-section/hooks/useCostsData';

const Cart: React.FC<CartProps> = ({
  infoMessages,
  paymentMethods,
  modalLogin,
  maxCoDValueExceededMessage,
  maxCartValueExceededMessage,
  ...props
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const title = formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' });

  // For <CartContent> and <CheckoutButton>
  const closeFlyouts = useCloseFlyouts();
  const { isEmpty, hasOutOfStockItems, isValid } = useCart();
  const { loggedIn } = useAccount();

  // For <CtaButton>
  const ctaButton: ReactNode = useMemo(() => {
    return (
      <CheckoutButton
        useButtonComponent
        buttonComponentProps={{
          variant: 'primary',
          className: 'w-full',
          'data-test': 'cart-page-order-summary-cta-button',
        }}
        text={formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
        link={'/checkout'}
        onClick={closeFlyouts}
        disabled={isEmpty || hasOutOfStockItems || !loggedIn || !isValid}
      />
    );
  }, [closeFlyouts, formatCartMessage, hasOutOfStockItems, isEmpty, loggedIn, isValid]);

  // For <Costs>
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'cart' });
  const { data } = useCart();

  const costsPropsFromParentPage = {
    locale,
    loading,
    costsToRender,
    total,
    freeShipping: data?.freeShipping,
  };

  const modalLoginContent = modalLogin?.[0];

  return (
    <div className="relative bg-background">
      <Container>
        <div className="flex flex-col px-20 pb-40 pt-16 lg:flex-row lg:items-start lg:gap-12 lg:px-47 lg:py-40">
          <CartContent
            className="lg:w-[70%]"
            infoMessages={infoMessages}
            maxCoDValueExceededMessage={maxCoDValueExceededMessage}
            maxCartValueExceededMessage={maxCartValueExceededMessage}
            {...props}
          />
          <OrderOverview
            costsPropsFromParentPage={costsPropsFromParentPage}
            ctaButton={ctaButton}
            page="cart"
            paymentMethods={paymentMethods}
            title={title}
            // Optional props
            showLogin={!loggedIn}
            modalLogin={modalLoginContent}
          />
        </div>
      </Container>
    </div>
  );
};

export default Cart;
