import { FC, useEffect, useState } from 'react';
import { Account } from 'shared/types/account';
import { Order } from 'shared/types/cart/Order';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useOrderInfoData from '../hooks/useOrderInfoData';

type ThankYouOrderInfoProps = {
  firstName?: Account['firstName'];
  lastName?: Account['lastName'];
  order?: Order;
};

type OrderInfoType = Array<{ label: string; value: string; subValue?: string }>;
const skeletonPlaceholder: OrderInfoType = [
  { label: 'Order number', value: '3737 3737 73737' },
  { label: 'Delivery by', value: 'Express' },
  {
    label: 'Delivery to',
    value: 'Guest User',
    subValue: 'street streetNumber, 11 111 city, country',
  },
  { label: 'Payment by', value: 'VISA **111' },
];

const ThankYouOrderInfo: FC<ThankYouOrderInfoProps> = ({ firstName, lastName, order }) => {
  const { formatMessage } = useFormat({ name: 'thank-you' });
  const [orderInfo, setOrderInfo] = useState<OrderInfoType>(skeletonPlaceholder);
  const [loading, setLoading] = useState(true);

  const { orderNumber, deliveryMethod, shippingAddress, paymentInfo } = useOrderInfoData(order);

  useEffect(() => {
    if (orderNumber && deliveryMethod && shippingAddress) {
      setOrderInfo([
        { label: formatMessage({ id: 'order.number', defaultMessage: 'Order number' }), value: orderNumber },
        { label: formatMessage({ id: 'delivery.by', defaultMessage: 'Delivery by' }), value: deliveryMethod },
        {
          label: formatMessage({ id: 'delivery.to', defaultMessage: 'Delivery to' }),
          value: `${firstName} ${lastName}` ?? '',
          subValue: shippingAddress,
        },
        { label: formatMessage({ id: 'payment.by', defaultMessage: 'Payment by' }), value: paymentInfo },
      ]);

      setLoading(false);
    }
  }, [firstName, lastName, deliveryMethod, orderNumber, shippingAddress, paymentInfo, formatMessage]);

  return (
    <div className="grid gap-16 border-b border-neutral-400 py-32 lg:gap-12 lg:py-36">
      {orderInfo.map(({ label, value, subValue }) => (
        <div key={value}>
          <div className="flex gap-24 lg:gap-12">
            <div className="min-w-[144px] lg:min-w-[342px]">
              <Typography className="w-fit text-16 leading-loose text-neutral-4" asSkeleton={loading}>
                {label + ':'}
              </Typography>
            </div>
            <div>
              <Typography
                className={`text-16 leading-loose text-neutral-4 ${subValue ? 'font-bold' : 'font-medium'}`}
                asSkeleton={loading}
              >
                {value}
              </Typography>
              {subValue &&
                subValue.split(',').map((line) => (
                  <Typography key={line} className="mt-4 text-16 leading-loose text-neutral-4" asSkeleton={loading}>
                    {line}
                  </Typography>
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThankYouOrderInfo;
