import React, { FC } from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import ResetPasswordForm from './reset-password-form';

export interface ResetPasswordProps {
  token?: string | string[];
  studio: ResetPasswordContent;
}

export interface ResetPasswordContent {
  passwordResetHeadline?: string;
  passwordFieldLabel?: string;
  confirmPasswordFieldLabel?: string;
  passwordTipsBoxTip1Description?: string;
  passwordTipsBoxTip2Description?: string;
  passwordTipsBoxTip3Description?: string;
  passwordTipsBoxTip4Description?: string;
  resetPasswordButtonLabel?: string;
  backToLoginButtonLabel?: string;
}

const ResetPassword: FC<ResetPasswordProps> = ({ token, studio }) => {
  return (
    <div className="bg-background py-32 lg:py-40">
      <Container>
        <ResetPasswordForm token={token} studio={studio} />
      </Container>
    </div>
  );
};

export default ResetPassword;
