import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import { InputProps } from 'components/commercetools-ui/atoms/input';
import InputPassword from 'components/commercetools-ui/atoms/input-password';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount } from 'frontastic';
import { ResetPasswordProps } from '.';

const ResetPasswordForm: FC<ResetPasswordProps> = ({ token, studio }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  //next/navigation
  const router = useRouter();

  //account actions
  const { resetPassword } = useAccount();

  const [isValid, setValid] = useState(false);

  //register data
  const [data, setData] = useState({ password: '', confirmPassword: '' });

  //error
  const [error, setError] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //handle text input change
  const handleChange: InputProps['onChange'] = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const passwordError = formatErrorMessage({
    id: 'form.password',
    defaultMessage:
      'Password must contain between 8 and 64 characters and  must contain at least 3 of the following elements: lowercase letter, capital letter, digit, special character',
  });
  const passwordConfirmationError = formatErrorMessage({
    id: 'form.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
  });

  const { fieldValidation, formValidation } = useYupValidation();

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup
        .string()
        .required(requiredError)
        .matches(
          /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,64}$/,
          passwordError,
        ),
      confirmPassword: yup
        .string()
        .required(requiredError)
        .oneOf([yup.ref('password'), null], passwordConfirmationError),
    });
  }, [requiredError, passwordError, passwordConfirmationError]);

  const validatePassword = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'password', value);
    },
    [fieldValidation, schema, data],
  );

  const validatePasswordConfirmation = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'confirmPassword', value);
    },
    [fieldValidation, schema, data],
  );

  useEffect(() => {
    setValid(formValidation(schema, data));
  }, [data, formValidation, schema]);

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //try registering the user with given credentials
    try {
      const response = await resetPassword(token as string, data.password);
      if (!response.accountId) {
        setError(
          formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }),
        );
      } else {
        setError('');
        router?.push('/');
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
    //processing ends
    setLoading(false);
  };

  const handleBackToLogin = () => {
    router.push('/login');
  };

  const passwordTips = [
    studio.passwordTipsBoxTip1Description,
    studio.passwordTipsBoxTip2Description,
    studio.passwordTipsBoxTip3Description,
    studio.passwordTipsBoxTip4Description,
  ];

  return (
    <div className="shadow-custom mx-20 bg-neutral-5 xl:mx-146">
      <div className="flex flex-col gap-32 px-12 py-24 lg:p-40">
        <Typography as="h3" className="text-24 font-bold leading-[30px] text-neutral-4">
          {studio.passwordResetHeadline}
        </Typography>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-32">
            <InputPassword
              id="password"
              name="password"
              label={studio.passwordFieldLabel}
              required
              value={data['password'] ?? ''}
              onChange={handleChange}
              validationSchema={validatePassword}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />

            <InputPassword
              id="confirm-password"
              name="confirmPassword"
              label={studio.confirmPasswordFieldLabel}
              required
              value={data['confirmPassword'] ?? ''}
              onChange={handleChange}
              validationSchema={validatePasswordConfirmation}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />

            <div className="bg-base-accent-3 p-24">
              {passwordTips.map((text, i) => {
                return (
                  <div key={i} className="mt-12 flex items-center gap-12 first:mt-0">
                    <span className="h-24 w-24 rounded-full bg-base-accent-1 text-neutral-5">
                      <CheckmarkIcon />
                    </span>
                    <p>{text}</p>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col items-center justify-between max-lg:gap-20 lg:flex-row">
              <Button type="submit" className="max-lg:w-full" disabled={!isValid || loading}>
                {studio.resetPasswordButtonLabel}
              </Button>

              <Button variant="secondary" className="max-lg:w-full" onClick={handleBackToLogin}>
                {studio.backToLoginButtonLabel}
              </Button>
            </div>
            {error && <Typography className="mb-12 text-12 text-accent-red">{error}</Typography>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
