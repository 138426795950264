import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Slider from 'components/commercetools-ui/atoms/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import Tile, { TileProps } from '../tile';

// Documentation:
// The HeroTileSlider layout follows the height behavior below:
// xs:
//     min screen  340 > height 140 (min)
//     max screen  479 > height 200 (max)
//     ratio: window width / image height ≈ 2.4
// --
// sm: min screen  480 > height 200 (min)
//     max screen  743 > height 314 (max)
//     ratio: window width / image height ≈ 2.38
// --
// md: fixed height 200
// --
// lg: min screen 1024 > height 280 (min)
//         screen 1460 > height 400 (max)
//     max screen  inf > height 400
//     ratio: window width / image height ≈ 3.65
// --

export interface HeroTileSliderProps {
  autoplay: boolean;
  delay: string;
  tiles: TileProps[];
}

const HeroTileSlider: React.FC<HeroTileSliderProps> = ({ autoplay, delay, tiles }) => {
  const [isTabletSize] = useMediaQuery(tablet);

  // This is the same definition as in HeroTileSliderSkeleton because Tailwind needs to have the styles explicitly defined on the component.
  // Importing the variable from a constants file will not work.
  const xsClassname = `h-[clamp(140px,calc(100vw/2.4),200px)]`;
  const smClassname = `sm:h-[clamp(200px,calc(100vw/2.38),314px)]`;
  const mdClassname = `md:h-[200px]`;
  const lgClassname = `lg:h-[clamp(280px,calc(100vw/3.65),400px)]`;
  const heightClassnames = `${xsClassname} ${smClassname} ${mdClassname} ${lgClassname}`;

  return (
    <div className={heightClassnames}>
      <Container>
        <Slider
          variation="hero-tile-slider"
          middleNavigation
          slidesPerView={1}
          arrows={isTabletSize}
          loop={true}
          autoplay={
            autoplay
              ? {
                  delay: 1000 * Number(delay),
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }
              : false
          }
        >
          {tiles.map((tile, index) => (
            <Tile
              key={index}
              {...tile}
              image={{ ...tile.image, loading: 'eager', priority: true, fill: true, alt: tile.image?.alt ?? '' }}
              roundedBorders={false}
              enableOverlay={false}
              enableHover={false}
              className="[&_img]:!static [&_img]:!max-h-[206px] [&_img]:!w-full sm:[&_img]:!max-h-[320px] md:[&_img]:!max-h-[200px] lg:[&_img]:!max-h-[400px]"
            />
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default HeroTileSlider;
