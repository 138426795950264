import { FC } from 'react';
import { COD_MAX_CART_VALUE } from 'helpers/constants/limitMaxOrderValue';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItemsList from './cart-items-list';
import EmptyCart from './empty-cart';
import InfoMessage from '../../info-message';
import { CartContentProps } from '../types';

const CartContent: FC<CartContentProps> = ({
  className,
  infoMessages,
  maxCoDValueExceededMessage,
  maxCartValueExceededMessage,
  ...props
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { isEmpty, totalItems, maxCoDCartValue, isValid } = useCart();

  const isExceedingCoDMaxCartValue = maxCoDCartValue > COD_MAX_CART_VALUE;

  return (
    <div className={className}>
      <div className="flex items-end gap-12">
        <h3 className="text-24 font-bold leading-[30px] text-neutral-4 lg:text-32 lg:leading-[40px]">
          {formatCartMessage({ id: 'cart', defaultMessage: 'Cart' })}
        </h3>
        {!isEmpty && (
          <span className="text-14 font-normal leading-[20px] text-neutral-3 lg:text-16 lg:leading-[26px]">
            {totalItems}
          </span>
        )}
      </div>
      {!isEmpty ? (
        <>
          {!isValid && maxCartValueExceededMessage && (
            <div className="mb-8 mt-16 flex flex-col lg:mb-12 lg:mt-20">
              <InfoMessage
                headline={maxCartValueExceededMessage[0].headline}
                text={maxCartValueExceededMessage[0].text}
                icon="info"
                iconColor="error"
                additionalClassName="bg-status-danger-bg"
              />
            </div>
          )}
          {isValid && isExceedingCoDMaxCartValue && maxCoDValueExceededMessage && (
            <div className="mb-8 mt-16 flex flex-col lg:mb-12 lg:mt-20">
              <InfoMessage
                headline={maxCoDValueExceededMessage[0].headline}
                text={maxCoDValueExceededMessage[0].text}
                icon="info"
                iconColor="error"
                additionalClassName="bg-status-danger-bg"
              />
            </div>
          )}
          <CartItemsList />
          {infoMessages && (
            <div className="mt-24 flex flex-col gap-12 lg:mt-32">
              {infoMessages.map((infoMessage, index) => (
                <InfoMessage key={index} {...infoMessage} />
              ))}
            </div>
          )}
        </>
      ) : (
        <EmptyCart {...props} />
      )}
    </div>
  );
};

export default CartContent;
