import React, { FC } from 'react';
import { Product } from 'shared/types/product/Product';
import { SwiperOptions } from 'swiper/types';
import Container from 'components/commercetools-ui/atoms/container';
import Slider from 'components/commercetools-ui/atoms/slider';
import Typography from 'components/commercetools-ui/atoms/typography';
import { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import Wrapper from 'components/HOC/wrapper';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import useTrack from './useTrack';
import ProductTile from '../product-tile';

export interface ProductSliderProps extends Partial<SwiperOptions> {
  products: Product[];
  title?: string;
  slidesPerView?: number;
  onProductClick?: (product: Product) => void;
  variant?: 'normal' | 'cart' | 'advanced';
  as?: ProductSliderAs;
}

export type ProductSliderAs = { ['productSlider']: { title: TypographyProps['as'] } } | undefined;

const ProductSlider: FC<ProductSliderProps> = ({
  products,
  title,
  onProductClick,
  breakpoints = {},
  as,
  variant,
  ...props
}) => {
  const { trackViewItem } = useTrack();

  return (
    <Wrapper
      className={`${
        variant === 'advanced' ? 'bg-background pl-20 pr-0' : 'bg-neutral-5 md:bg-background'
      } min-h-[400px] pb-24 lg:min-h-[575px]`}
      background="background"
    >
      <Container>
        {title ? (
          <div className="pt-12">
            <Typography
              className="text-24 font-bold leading-[30px] text-neutral-4 md:text-32 md:leading-[40px]"
              as={as ? as.productSlider.title : 'h3'}
            >
              {title}
            </Typography>
          </div>
        ) : null}
        <div className={variant === 'advanced' ? 'mt-0 sm:-mt-25 md:-mt-205' : 'mt-20 md:mt-28'}>
          <div className="relative mt-6 w-full">
            <Slider
              variation={variant === 'advanced' ? 'product-advanced-slider' : undefined}
              className="grid bg-white"
              slidesPerView={1.3}
              slidesPerGroup={1}
              topNavigation
              allowTouchMove
              spaceBetween={8}
              breakpoints={{
                [tablet]: {
                  slidesPerView: 2.3,
                  slidesPerGroup: 2,
                  spaceBetween: 0,
                  ...(breakpoints[tablet] ?? {}),
                },
                [mediumDesktop]: {
                  slidesPerView: 4.3,
                  slidesPerGroup: 4,
                  spaceBetween: 0,
                  ...(breakpoints[mediumDesktop] ?? {}),
                },
              }}
              {...props}
            >
              {products.map((product, index) => (
                <ProductTile
                  key={product.productId}
                  product={product}
                  onClick={() => {
                    trackViewItem(product, index + 1);
                    onProductClick?.(product);
                  }}
                  className="border-r border-neutral-2 bg-white max-sm:border-0 max-sm:p-12 sm:mt-24 sm:max-md:[&:nth-child(2n)]:border-r-0 md:max-lg:[&:nth-child(3n)]:border-r-0 lg:[&:nth-child(4n)]:border-r-0"
                />
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ProductSlider;
