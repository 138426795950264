'use client';

import { Product } from 'shared/types/product/Product';
import ProductSlider, {
  ProductSliderAs,
  ProductSliderProps,
} from 'components/commercetools-ui/organisms/product/product-slider';
import { PRODUCT_DETAIL_PAGE } from 'helpers/constants/dynamicPages';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

function OtherProductsTastic({ data, page }: TasticProps<DataSource<{ items: Product[] }> & ProductSliderProps>) {
  if (!data?.data?.dataSource?.items) return <></>;

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mobile, tablet, desktop, ...props } = data;

  if (data.variant === 'cart') {
    return (
      <div className="bg-neutral-200 pb-24 lg:pb-32">
        <ProductSlider {...props} products={data.data.dataSource.items ?? []} />
      </div>
    );
  }

  const getAs = (): ProductSliderAs => {
    const pageIdentifier = page.isDynamic ? page.pageFolderType : page.name;

    switch (pageIdentifier) {
      case PRODUCT_DETAIL_PAGE:
        return { productSlider: { title: 'h4' } };
      default:
        return undefined;
    }
  };

  return <ProductSlider {...props} products={data.data.dataSource.items ?? []} as={getAs()} />;
}

export default OtherProductsTastic;
