'use client';

import React from 'react';
import HeroTileSlider, { HeroTileSliderProps } from 'components/commercetools-ui/organisms/content/hero-tile-slider';
import { TasticProps } from 'frontastic/tastics/types';

const HeroTileSliderTastic = ({ data }: TasticProps<HeroTileSliderProps>) => {
  return <HeroTileSlider autoplay={data.autoplay} delay={data.delay} tiles={data.tiles} />;
};

export default HeroTileSliderTastic;
