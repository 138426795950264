'use client';

import React from 'react';
import TilesGroup, {
  Props as TilesGroupProps,
  TilesGroupAs,
} from 'components/commercetools-ui/organisms/content/tiles-group';
import { HOME_PAGE } from 'helpers/constants/staticPages';
import { TasticProps } from 'frontastic/tastics/types';

export interface Props {
  data: TilesGroupProps;
}

const TilesGroupTastic = ({ data, page }: TasticProps<TilesGroupProps>) => {
  const getAs = (): TilesGroupAs => {
    const pageIdentifier = page.isDynamic ? page.pageFolderType : page.name;

    switch (pageIdentifier) {
      case HOME_PAGE:
        return { tile: { title: 'h4' } };
      default:
        return undefined;
    }
  };

  return <TilesGroup {...data} as={getAs()} />;
};

export default TilesGroupTastic;
