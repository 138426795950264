'use client';

import React from 'react';
import ContentSlider, { ContentSliderAs } from 'components/commercetools-ui/organisms/content-slider';
import { ContentSliderProps } from 'components/commercetools-ui/organisms/content-slider/types';
import { HOME_PAGE } from 'helpers/constants/staticPages';
import { TasticProps } from '../types';

export interface Props {
  data: ContentSliderProps;
}

const ContentSliderTastic = ({ data, page }: TasticProps<ContentSliderProps>) => {
  const getAs = (): ContentSliderAs => {
    const pageIdentifier = page.isDynamic ? page.pageFolderType : page.name;

    switch (pageIdentifier) {
      case HOME_PAGE:
        return { contentSlider: { title: 'h2' } };
      default:
        return undefined;
    }
  };
  return <ContentSlider {...data} as={getAs()} />;
};

export default ContentSliderTastic;
