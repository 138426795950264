'use client';

import React from 'react';
import Cart from 'components/commercetools-ui/organisms/cart';
import { CartProps } from 'components/commercetools-ui/organisms/cart/types';
import { useProduct } from 'frontastic';
import { TasticProps } from '../types';

const CartTastic = ({ data }: TasticProps<CartProps>) => {
  const { categories = [] } = useProduct();

  return <Cart {...data} categories={categories} />;
};

export default CartTastic;
