import { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useAccount } from 'frontastic';
import useBottomSectionProps from '../hooks/useBottomSectionProps';

type ThankYouFooterProps = {
  loading: boolean;
};

const ThankYouFooter: FC<ThankYouFooterProps> = ({ loading }) => {
  const { loggedIn } = useAccount();

  const { bottomSectionProps } = useBottomSectionProps(loggedIn);

  return (
    <div className="grid border-b border-neutral-400 pb-24 pt-16 lg:border-b-0 lg:pb-0 lg:pt-36">
      <Typography
        as="h3"
        className="mb-16 w-fit text-20 leading-[20px] text-neutral-4 lg:mb-8 lg:leading-normal"
        asSkeleton={loading}
      >
        {bottomSectionProps?.title}
      </Typography>
      <Typography className="mb-20 w-fit  text-16 text-neutral-4" asSkeleton={loading}>
        {bottomSectionProps?.subtitle}
      </Typography>
      <Button className="w-fit" asSkeleton={loading} onClick={bottomSectionProps.onClick} variant="secondary">
        {bottomSectionProps?.cta}
      </Button>
    </div>
  );
};

export default ThankYouFooter;
