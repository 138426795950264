import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Order } from 'shared/types/cart/Order';
import Container from 'components/commercetools-ui/atoms/container';
import ThankYouBanner from 'components/commercetools-ui/atoms/thank-you-banner';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import ThankYouOrderInfo from './components/order-info';
import ThankYouFooter from './components/thank-you-footer';
import ThankYouHeader from './components/thank-you-header';
import OrderSummary from '../order-summary';

const ThankYouContent: FC = () => {
  const { formatMessage } = useFormat({ name: 'thank-you' });

  const searchParams = useSearchParams();

  const orderId = searchParams.get('orderId');

  const [order, setOrder] = useState<Order>();
  const { getOrder } = useCart();

  useEffect(() => {
    if (!order) {
      getOrder(orderId as string).then((res) => setOrder(res));
    }
  }, [getOrder, order, orderId]);

  return (
    <Container>
      <div className="flex flex-col gap-24 bg-neutral-200 pt-16 lg:gap-32 lg:p-50 lg:pt-32">
        <ThankYouBanner
          thankYouScreenTitle={formatMessage({ id: 'banner.title', defaultMessage: 'Thank you for your order!' })}
          thankYouScreenSubtitle={formatMessage({ id: 'banner.subtitle', defaultMessage: 'Your order is confirmed' })}
          thankYouScreenCtaButtonLabel="Homepage"
          thankYouScreenCtaButtonUrl={'/'}
          additionalClassName="!mx-20 !py-32 !px-12"
        />
        <div className="mx-20 flex flex-col gap-32 lg:mx-0 lg:flex-row lg:items-start lg:gap-26 lg:px-20">
          <div className="shadow-custom bg-white px-16 lg:w-[788px] lg:rounded-md lg:px-24 lg:py-36 ">
            <ThankYouHeader email={order?.email} />
            <ThankYouOrderInfo
              firstName={order?.shippingAddress?.firstName}
              lastName={order?.shippingAddress?.lastName}
              order={order}
            />
            <ThankYouFooter loading={!order?.sum} />
          </div>

          <OrderSummary order={order} />
        </div>
      </div>
    </Container>
  );
};

export default ThankYouContent;
