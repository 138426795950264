import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Image from 'frontastic/lib/image';
import { FooterColumn } from '.';

export interface FooterBottomColumnProps {
  wrapperClassName?: string;
  columns?: FooterColumn;
  elementClassName?: string;
  logoClassName?: string;
  containerClassName?: string;
}

const FooterBottomColumn = ({
  wrapperClassName,
  columns,
  elementClassName,
  logoClassName,
  containerClassName,
}: FooterBottomColumnProps) => {
  if (!columns) {
    return null;
  }

  return (
    <div className={`flex flex-col items-start gap-20 ${wrapperClassName}`}>
      <Typography className="whitespace-pre-wrap text-16 font-bold leading-5 text-neutral-4">
        {columns?.header}
      </Typography>
      <ul className={`flex flex-wrap gap-4 ${containerClassName}`}>
        {columns?.links?.map((item, i) => (
          <Link key={i} link={item.reference}>
            <li
              key={JSON.stringify(item.reference)}
              className={`flex h-44 w-88 flex-col items-center justify-center gap-67 border border-base-accent-2 bg-neutral-5 p-8 ${elementClassName}`}
            >
              <Image {...item.logo} className={logoClassName} />
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default FooterBottomColumn;
