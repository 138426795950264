function AdvancedProductSliderAbove744({}) {
  return (
    <svg width="525" height="322" viewBox="0 0 525 322" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-118.407 141.877C-120.467 3.14276 95.7785 -170.722 277.012 -173.413C458.245 -176.104 467.297 -33.1369 502.539 82.6581C542.009 212.342 546.828 324.673 344.35 321.118C202.941 318.636 -98.739 337.007 -118.407 141.877Z"
        fill="#D0ECFF"
      />
    </svg>
  );
}

export default AdvancedProductSliderAbove744;
