import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import useClassNames from 'helpers/hooks/useClassNames';
import { Reference } from 'types/reference';
import Image, { ImageProps } from 'frontastic/lib/image';

export interface TileProps extends Pick<TypographyProps, 'as'> {
  image?: ImageProps;
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  roundedBorders?: boolean;
  enableOverlay?: boolean;
  enableHover?: boolean;
  className?: string;
}

const Tile: React.FC<TileProps> = ({
  image,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
  roundedBorders = true,
  enableOverlay = true,
  enableHover = true,
  className = '',
  as = 'h2',
}) => {
  const imageClassName = useClassNames([roundedBorders && 'lg:rounded-md', enableOverlay && 'brightness-75']);

  const isValidLink = () => {
    if (ctaReference && ctaReference.type === 'page-folder') {
      return !!ctaReference.pageFolder._url;
    }
    if (ctaReference && ctaReference.type === 'link') {
      return ctaReference.link !== null && ctaReference.link !== '';
    }

    return false;
  };
  const tileContent = (
    <>
      <div className="relative h-full">
        {image && <Image {...image} alt={title} style={{ objectFit: 'cover' }} className={imageClassName} />}
      </div>
      <div className="absolute left-0 top-1/2 w-full -translate-y-1/2 text-center">
        {subtitle ? (
          <Typography className="text-shadow text-center text-14 leading-loose text-white md:text-16 lg:font-medium">
            {subtitle}
          </Typography>
        ) : null}
        {title ? (
          <Typography as={as} className="text-shadow mt-18 text-center text-26 font-medium  text-white lg:text-28">
            {title}
          </Typography>
        ) : null}
        {ctaLabel && isValidLink() ? (
          <Link link={ctaReference}>
            <Button className="mt-24">
              <Typography as="span" className="text-12">
                {ctaLabel}
              </Typography>
            </Button>
          </Link>
        ) : null}
      </div>
    </>
  );

  return (
    <div className={`relative w-full ${className}`}>
      {!ctaLabel && isValidLink() ? (
        <Link link={ctaReference} className="block h-full">
          {tileContent}
          {enableHover ? (
            <div className="absolute inset-0 h-full w-full overflow-hidden bg-neutral-4 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-50"></div>
          ) : null}
        </Link>
      ) : (
        <>{tileContent}</>
      )}
    </div>
  );
};

export default Tile;
