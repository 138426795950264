import { FC } from 'react';
import { Account } from 'shared/types/account';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

type ThankYouHeaderProps = {
  email?: Account['email'];
};

const ThankYouHeader: FC<ThankYouHeaderProps> = ({ email }) => {
  const { formatMessage } = useFormat({ name: 'thank-you' });

  return (
    <div className="grid justify-items-start gap-24 border-b-0 border-neutral-400 pt-32 lg:p-0">
      {/* Title */}
      <Typography
        as="h3"
        asSkeleton={!email}
        className={`text-20 text-neutral-4 ${email ? 'leading-tight' : 'leading-loose'}`}
      >
        {formatMessage({ id: 'your.order.summary', defaultMessage: 'Your Order Summary' })}
      </Typography>
    </div>
  );
};

export default ThankYouHeader;
