import React, { FC } from 'react';
import { Product } from 'shared/types/product/Product';
import { SwiperOptions } from 'swiper/types';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import { Reference } from 'types/reference';
import Image, { ImageProps } from 'frontastic/lib/image';
import AdvancedProductSliderAbove744 from '../../content/shapes/AdvancedProductSliderAbove744';
import Below744 from '../../content/shapes/Below744';
import ProductSlider from '../product-slider';

export interface AdvancedProductSliderProps extends Partial<SwiperOptions> {
  products: Product[];
  image: ImageProps;
  title: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  backgroundColor: string;
  as: AdvancedProductSliderAs;
  slidesPerView?: number;
  onProductClick?: (product: Product) => void;
}

export type AdvancedProductSliderAs = { ['advancedProductSlider']: { title: TypographyProps['as'] } } | undefined;

const AdvancedProductSlider: FC<AdvancedProductSliderProps> = ({
  products,
  image,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
  backgroundColor,
  as,
  ...props
}) => {
  const sliderBackground = (
    <div className="relative h-[390px] md:h-[487px]">
      <Image {...image} alt={title} fill style={{ objectFit: 'cover', position: 'absolute' }} />
      <div className="absolute !block h-full w-full px-20 py-24 md:px-46 md:py-60">
        <div className="flex h-full flex-col items-start gap-24 md:gap-32">
          <div className={`absolute inset-0 overflow-hidden`} style={{ color: backgroundColor }}>
            <div className="hidden h-[320px] w-full md:block md:[&>svg]:!h-full md:[&>svg]:!w-auto">
              <AdvancedProductSliderAbove744 />
            </div>
            <div className={`md:hidden ${ctaLabel ? '' : '[&>svg]:!h-[190px]'}`}>
              <Below744 />
            </div>
          </div>
          <div className="flex flex-col gap-12  md:w-[328px]">
            <Typography
              as={as ? as.advancedProductSlider.title : 'h3'}
              className="text-24 font-bold leading-[30px] text-neutral-4 md:text-32 md:leading-[40px]"
            >
              {title}
            </Typography>
            {subtitle ? <Typography className="text-16 leading-[20px] text-neutral-4">{subtitle}</Typography> : null}
          </div>
          {ctaLabel && ctaReference ? (
            <div className="md:w-[328px]">
              <Button>
                <Typography as="span" className="text-16">
                  {ctaLabel}
                </Typography>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <Container>
      <div className="relative">
        {ctaReference ? <Link link={ctaReference}>{sliderBackground}</Link> : sliderBackground}
        <ProductSlider variant="advanced" {...props} products={products} />
      </div>
    </Container>
  );
};

export default AdvancedProductSlider;
