import React, { FC } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import Image, { ImageProps } from 'frontastic/lib/image';
import { FooterColumn } from '.';
import FooterBottomColumn from './footer-bottom-column';

interface Props {
  logo?: ImageProps;
  socialMedia?: FooterColumn;
  paymentMethods?: FooterColumn;
  deliveryOptions?: FooterColumn;
  partnerWebsites?: FooterColumn;
}

const FooterBottom: FC<Props> = ({ logo, socialMedia, paymentMethods, deliveryOptions, partnerWebsites }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <>
      <div className="flex flex-col items-center gap-32 px-32 pb-32 md:px-46">
        <div className="flex w-full flex-col gap-20 md:grid md:grid-cols-4 md:gap-19">
          {logo && (
            <div className="w-160">
              <Image {...logo} alt="logo" />
            </div>
          )}

          <FooterBottomColumn columns={paymentMethods} wrapperClassName="hidden md:flex" />

          <FooterBottomColumn columns={deliveryOptions} />

          <FooterBottomColumn columns={partnerWebsites} />

          <FooterBottomColumn
            columns={socialMedia}
            elementClassName="!h-40 !w-40 shrink-0 rounded-full !p-0 !border-none"
            containerClassName="!gap-20"
          />
        </div>
        <Typography className="text-12 leading-[15px] text-neutral-4">
          {`Selgros ${new Date().getFullYear()} ${formatMessage({
            id: 'powered',
            defaultMessage: '©  all rights reserved',
          })}`}
        </Typography>
      </div>
    </>
  );
};

export default FooterBottom;
